.flex-gallery {
    /* min-height: 300px; */
    display: flex;
    flex-wrap: wrap;
    gap: 3vw;
    justify-content: space-between;
    margin: 0 auto;
    /* justify-self: center; */
}

.grid-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-auto-rows: 1fr; */
}

@media (max-width: 768px) {
    .flex-gallery {
        justify-content: space-evenly;
    }
    .grid-gallery {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
    }
}