header {
    height: 86px;
    padding-bottom: 5.5vw;
    width: 100%;
}

nav {
    align-items: flex-end;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;
}

nav h1 {
    color: #000;
    cursor: pointer;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 4px;
    text-decoration: none;
    width: 35%;
}

nav h1:hover {
    color: #9B5496;
}

.link-list {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    min-width: 55%;
}

.link-list li {
    box-sizing: border-box;
    color: #000;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    margin-left: 14px;
    padding-bottom: 8px;
    text-align: center;
    text-decoration: none;
}

.link-list li.active {
    border-bottom: 4px solid #A680A3;
    color: #000;
    font-weight: 700;
    padding-bottom: 4px;
}

.link-list li:hover {
    border-bottom: 4px solid #A680A3;
    color: #666;
    padding-bottom: 4px;
}

@media (max-width: 768px) {
    .body {
        width: 90%;
    }

    header {
        height: auto;
    }

    .header-spacer {
        display: none;
    }

    nav {
        flex-direction: column;
        padding: 0;
        width: 100%;
    }

    nav h1 {
        text-align: center;
        padding: 8px 0;
        width: 100%;
    }

    nav h1:hover {
        color: inherit;
    }

    .link-list {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .link-list li {
        align-self: center;
        margin: 0;
    }

    .link-list li.active {
        border-bottom: none;
        padding-bottom: 8px;
    }

    .link-list li:hover {
        border-bottom: none;
        padding-bottom: 8px;
    }
}

/* unused */

/* .mobile-name {
    display: none;
} */

/* .dropdown {
    position: relative;
} */

/* .dropdown-content {
    background-color: #f9f9f9;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
    display: none;
    left: 50%;
    min-width: 160px;
    position: absolute;
    transform: translateX(-50%);
    z-index: 1;
} */

/* .dropdown-content p {
    color: #333;
    margin: 5px 0;
} */

/* .dropdown-content p:hover {
    color: #666;
} */

/* .dropdown:hover .dropdown-content {
    display: block;
} */

/* .open-dropdown .dropdown-content {
    display: block;
} */

/* @media (max-width: 768px) {
        .mobile-name {
        display: block;
        align-self: end;
        color: #555;
        cursor: pointer;
        display: relative;
        font-size: 30px;
        font-weight: 300;
        letter-spacing: 5px;
        text-align: center;
        width: 100%;
    }
    
    .dropdown {
        padding-bottom: 0 !important;
    }

    .dropdown span {
        display: none;
    }

    .dropdown-content {
        width: 100%;
        background-color: white;
        box-shadow: none;
        left: 0;
        transform: translate(0, 0);
        position: static;
        display: block;
    }

    .dropdown-content p {
        box-sizing: border-box;
        color: #333;
        cursor: pointer;
        font-size: 13px;
        margin: 0;
        padding: 0 7px 8px;
        text-align: center;
        text-decoration: none;
    }

    .dropdown-content hr {
        display: none;
    }
} */