@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  /* color: #1A0F26; */
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  /* scrollbar-gutter: stable both-edges; */
}

.body {
  color: #1A0F26;
  margin: 0 auto;
  width: 92vw;
  font-size: calc(12px + 0.5vw);
}

a {
  color: #A680A3;
  text-decoration: none;
}

a:hover {
  color: #9B5496;
}

.pdf {
  font-weight: 700;
}

hr {
  background-color: #ddd;
  border: none;
  height: 1px;
}

.title {
  color: #000;
  font-size: calc(22px + 2vw);
  font-weight: 700;
  padding: 1.2vh 0;
}

.text-center {
  text-align: center;
}

.label {
  color: #333;
  font-size: calc(12px + 0.75vw);
  font-weight: 700;
}

.page-title {
  margin-top: 60px;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.page-title h3 {
  font-size: 18px;
  font-weight: 400;
}

main {
  display: flex;
  justify-content: space-between;
}

.img-preview {
  width: 35%;
}

.img-preview img {
  width: 100%;
}

footer {
  height: 130px;
}

@media (max-width: 768px) {
  main {
    position: relative;
    flex-direction: column;
    align-items: center;
  }
}