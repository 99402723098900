.contact-img {
    width: 45%;
}

.contact-img img {
    width: 140%;
}

.contact-spacer {
    height: 45px;
    width: 100%;
}

.contact-title {
    color: #000;
    font-size: calc(22px + 2vw);
    font-weight: 700;
    padding: 10px 0;
}

.contact-info {
    font-weight: 500;
    margin-top: 8%;
    text-align: left;
    width: 50%;
}

.contact-info-section {
    align-items: center;
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.contact-info-section p {
    display: inline-block;
}

.clipboard {
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.clipboard-hover {
    background-color: #f9f9f9;
    border: none;
    border-radius: 30px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
    /* left: 50%; */
    min-width: 160px;
    opacity: 0;
    padding: 0 1%;
    position: absolute;
    text-align: center;
    /* transform: translateX(-50%); */
    transition: 0.5s;
}

.clipboard:hover .clipboard-hover {
    opacity: 1;
}

.clipboard-popup {
    background-color: #f9f9f9;
    border-radius: 30px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);
    min-width: 160px;
    opacity: 0;
    position: absolute;
    text-align: center;
    z-index: 1;
}

.copyClick {
    animation: showPopUp 3s 1;
}

@keyframes showPopUp {
    25% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .contact-img {
        display: block;
        width: 100%;
        z-index: -1;
    }

    .contact-img img {
        width: 100%;
    }

    .contact-info {
        background-color: #FFFFFFA0;
        border-radius: 10%;
        box-shadow: 0 0 18px 20px #FFFFFFA0;
        color: black;
        font-size: 18px;
        padding: 1%;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        width: 80%;
    }

    .contact-info a {
        font-weight: 700;
        color: #9B5496;
    }

    .home-content .title {
        color: black;
    }
}