@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

svg {
    vertical-align: middle;
}

.hb {
    position: fixed;
    width: 80%;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    font-family: 'Kaushan Script';
    text-align: center;
    font-size: calc(8vw + 30px);
    background: linear-gradient( rgb(255, 0, 255), #b00);
    background-clip: text;
    color: transparent;
    animation: hb-slide 1s ease 1;
}

.b-num {
    position: fixed;
    top: 50%;
    left: 70%;
    width: 20%;
    font-family: 'Kaushan Script';
    text-align: center;
    font-size: calc(8vw + 30px);
    background: linear-gradient( rgb(255, 0, 255), #b00);
    background-clip: text;
    color: transparent;
    animation: b-num-slide 1s ease 1;
}

@keyframes hb-slide {
    from {
        transform: translate(-50%, -100%)
    }
    to {
        transform: translate(-50%, 0)
    }

}

@keyframes b-num-slide {
    from {
        left: 100%;
    }
    to {
        left: 70%
    }
}

.cake-container {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    position: relative;
    margin: 0 auto;
    z-index: 1;
    max-width: 1050px;
}

.candle {
    position: relative;
    top: 0;
    left: 0;
}

.candle-2 {
    width: 40%;
    position: absolute;
    bottom: 5%;
    left: 0;
}

.candle-1 {
    width: 40%;
    position: absolute;
    bottom: 5%;
    left: 25%;
    transform: rotate(2deg);
}

.wick {
    position: absolute;
    width: 60%;
    left: 20%;
}

.wick.two {
    bottom: 85%;
    left: 18%;
}

.wick.one {
    left: 22%;
    bottom: 85%;
}

.outer {
    transform-origin: 50% center;
    /* transform: skewX(-2deg); */
    animation: lit 0.5s 1, outer 4s 0.5s infinite;
}

.inner {
    transform-origin: 50% 60%;
    animation: lit 0.5s 1, inner 5s 0.5s infinite;
    /* animation: outer 3s infinite; */
}

@keyframes lit {
    from {
        transform: scale(0.2);
    }

    to {
        transform: scale(1);
    }
}

@keyframes outer {
    0% {}

    10% {
        transform: skewX(-1deg) scale(0.95);
        opacity: 0.5;
    }

    25% {
        transform: skewX(4deg);
        opacity: 0.7;
    }

    50% {
        transform: skewX(1deg) rotate(1deg) scaleX(1.05);
        opacity: 1;
    }

    75% {
        transform: skewX(-3deg);
        opacity: 0.8;
    }

    90% {
        transform: skewX(-1deg) scale(0.95);
        opacity: 0.5;
    }
}

@keyframes inner {
    0% {}

    10% {
        transform: scaleY(0.8) rotate(-5deg);
        opacity: 0.9;
    }

    40% {
        transform: scaleY(0.7) rotate(2deg);
    }

    50% {
        transform: scaleY(1) rotate(0deg);
        opacity: 0.6;
    }

    70% {
        transform: scaleY(0.8) rotate(-5deg);
        opacity: 0.7;
    }

    90% {
        transform: scaleY(0.6);
    }

    100% {}
}

#lighter {
    aspect-ratio: 1;
    width: 35%;
    position: fixed;
    bottom: 8%;
    left: 60%;
}

.lighter-top {
    transform-origin: 50% 50%;
    transition: transform 1s;
}

.lighter-top.open {
    transform: rotate(180deg);
}

.lighter-outer {
    transform-origin: 30% center;
    animation: lit 0.5s 1, lighter 3s 0.5s infinite;
    opacity: 0.8;
}

.lighter-inner {
    transform-origin: 30% center;
    animation: lit 0.5s 1, lighter 3s 0.5s infinite reverse;
    opacity: 0.8;
}

@keyframes lighter {
    0% {
        transform: scaleY(1);
        opacity: 0.8;
    }

    20% {
        transform: scaleY(1.05) skewX(-0.75deg);
        opacity: 1;
    }

    40% {
        transform: scaleY(1);
        opacity: 0.6;
    }

    60% {
        transform: scaleY(1.1) skewX(0.5deg);
        opacity: 1;
    }

    80% {
        transform: scaleY(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scaleY(1);
        opacity: 0.8;
    }
}

.beer {
    animation: beer-slide 1s ease-out 1;
    height: 60vh;
    position: fixed;
    right: 50%;
    top: 60%;
    transform: translate(50%, -50%);
}

@keyframes beer-slide {
    from {
        right: 100%;
    }

    to {
        right: 50%;
    }
}

.dim {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #000000c0;
    position: fixed;
    animation: dim-darken 1s 1;
}

@keyframes dim-darken {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}